@tailwind base;
@tailwind components;
@tailwind utilities;


.navbar{
  border-bottom: 2px solid grey;
}
.whycollege,.courses,.topcolleges,.colleges,.college-overview,.courses-offered{
  background-color: #F8F9FA;
}
.fixed-height {
  height: 180px; /* Set the desired height */
  object-fit: cover; /* Ensure the image covers the area while maintaining aspect ratio */
  width: 100%; /* Ensure the image takes the full width of the container */
}
.highlighted{
  color: #0E7490;
}

.applynow{
  background-color: #0E7490;
  color: white;
}
.footercomp{
  background-image: url("./assets/footer_bg.png")!important;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-link-group {
  display: flex;
  flex-direction: column;
}

.footer-link {
  margin-bottom: 8px; /* Adjust the spacing as needed */
  text-align: left; /* Ensure text is left-aligned */
}
.custom-navbar a:hover {
  color: #0E7490 ; /* Change this to the desired hover color */
}

 

.hero-container {
  position: relative;
}

.carousel-item {
  position: relative;
  height: 400px; /* Set a fixed height for all carousel items */
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area */
  position: relative;
  z-index: 0; /* Ensure the images are below the overlay */
}
.snap-mandatory{
  border-radius: 0;
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: 1; /* Ensure the overlay is on top of the images */
}
.hero-content, .hero-discription {
  position: relative;
  z-index: 5; /* Ensure the HeroContent is above the overlay */
  color: white; /* Ensure the text is visible on the dark background */
  text-align: center;
  width: 100%; /* Ensure the content spans the full width */
  top: 50%; /* Center the content vertically */
  transform: translateY(-50%); /* Adjust for the vertical centering */
}

.hero-search {
  position: relative;
  z-index: 1000 !important;
  width: 100%; /* Ensure the search bar spans the full width */
  display: flex; /* Ensure the search bar elements are aligned properly */
  justify-content: center; /* Center the search bar elements */
  background-color: rgba(173, 172, 172, 0.993); /* Ensure the background is visible */
  border-radius: 5px; /* Add border radius for better aesthetics */
}
.hero-search input:focus {
  outline: none;
  box-shadow: none; /* Remove any box-shadow if present */
  border: none; /* Remove any border if present */
}

.hero-discription {
  position: absolute;
  z-index: 5;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
